<script>
// import SwiperCore from "swiper";
// import {
//   Swiper,
//   SwiperSlide
// } from "swiper/vue";
// import "swiper/swiper-bundle.css";

// SwiperCore.use([]);
// import flatPickr from "vue-flatpickr-component";
// import {
//   SimpleBar
// } from "simplebar-vue3";
// import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import DropZone from "@/components/widgets/dropZone.vue";
// import axios from "axios";
import axios from "@/config/axios";

import { BASE_URL } from "../helpers/constants";
// import Revenue from "./revenue";
// import SalesLocation from "./sales-location";

export default {
  components: {
    Layout,
    // Swiper,
    // SwiperSlide,
    DropZone,
    PageHeader,
  },
  data() {
    return {
      title: "Ajouter un document",
      files: [],
      loading: false,
      loaded: false,
      file: "",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter un document",
          active: true,
        },
      ],
    };
  },
  watch: {
    files: {
      handler: function (currentValue) {
        return currentValue;
      },
      deep: true,
    },
  },
  methods: {
    handleBack() {
      this.files = [];
      this.loaded = false;
    },
    saStartLoading(title) {
      // Swal loading animation and indicator
      Swal.fire({
        title: title || "Traitement du document...",
        // Bootstrap  spinner
        html: `<div class="spinner-border text-primary" role="status"></div>`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    },
    saStopLoading() {
      Swal.close();
    },
    drop(e) {
      this.file = e.dataTransfer.files[0];
      console.log(this.file);
      this.processFile();
    },
    selectedFile() {
      this.file = document.querySelector(".dropzoneFile").files[0];
      console.log(this.file);
      this.processFile();
    },
    processFile() {
      this.loading = true;
      this.saStartLoading();
      const formData = new FormData();
      formData.append("document", this.file);

      return axios
        .post(BASE_URL + "/document/verify", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          this.loading = false;
          console.log(data);
          this.files = data.data.societies.map((file) => {
            return {
              ...file,
              status: "pending",
            };
          });
          this.loaded = true;
          this.saStopLoading();

          this.$saSuccess(
            `${this.files.length < 10 ? "0" : ""}${this.files.length} élément${
              this.files.length > 1 ? "s" : ""
            } ${this.files.length > 1 ? "ont" : "a"} été trouvé${
              this.files.length > 1 ? "s" : ""
            }`,
            ""
          );
        })
        .catch((error) => {
          this.saStopLoading();
          this.$saError(
            "Oups !",
            error.response.data.message ||
              "Une erreur est survenue lors du traitement du document"
          );
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleSendEmails() {
      await axios
        .get(BASE_URL + "/not-sended")
        .then(async ({ data }) => {
          this.files = data.data.societies;

          for (const file of this.files) {
            if (file.status === "success") continue;
            await this.sendEmail(file);
          }

          if (this.files.every((file) => file.status === "success")) {
            this.$saSuccess(
              "Documents envoyés",
              "Les documents ont été envoyés avec succès"
            );
            this.$router.push({ name: "cases-list" });
          }

          if (this.files.some((file) => file.status === "error")) {
            this.$saError(
              "Documents non envoyés",
              "Certains documents n'ont pas été envoyés"
            );

            console.log(data.data.directory);

            // this.$router.replace({
            //   name: "cases-details",
            //   params: {
            //     id: data.data.directory.id,
            //   },
            // });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleResendEmail(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          title: "Voulez-vous vraiment renvoyer le message ?",
          text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, renvoyer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      file.status = "loading";
      try {
        const { data } = await axios.post(BASE_URL + "/resend-email", {
          matricule: file.matricule,
        });
        console.log(data);
        this.$store.dispatch("documents/findAll");
        this.$saSuccess("Email envoyé", "Le message a été renvoyé");
      } catch (error) {
        this.$store.dispatch("documents/findAll");
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
    },

    async handleResendSMS(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          title: "Voulez-vous vraiment renvoyer le message ?",
          text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, renvoyer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      file.status = "loading";
      try {
        const { data } = await axios.post(BASE_URL + "/resend-sms", {
          matricule: file.matricule,
        });
        console.log(data);
        this.$store.dispatch("documents/findAll");
        this.$saSuccess("SMS envoyé", "Le message a été renvoyé");
      } catch (error) {
        this.$store.dispatch("documents/findAll");
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
    },
    async sendEmail(file) {
      file.status = "loading";
      try {
        const { data } = await axios.post(BASE_URL + "/send-message", {
          matricule: file.matricule,
        });
        console.log(data);
        file.status = "success";
      } catch (error) {
        file.status = "error";
        console.log(error);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row dash-nft">
      <div class="col-xxl-12">
        <!-- <div class="col-xxl-9"> -->
        <div class="row">
          <div class="col-xxl-12">
            <div v-if="!files?.length" class="row">
              <div class="d-flex align-items-center mb-3">
                <button
                  type="button"
                  class="btn btn-light btn-xl"
                  @click="$router.back()"
                >
                  <i class="mdi mdi-arrow-left"></i> Retour
                </button>
              </div>

              <p class="text-muted">
                Importez votre document et validez l'envoi des emails
              </p>

              <DropZone
                @drop.prevent="drop"
                @change="selectedFile"
                class="mb-4 ml-2 mr-2"
              />
            </div>
            <div v-else>
              <!-- Back button -->
              <div class="d-flex align-items-center mb-3">
                <button
                  type="button"
                  class="btn btn-light btn-xl"
                  @click="handleBack"
                >
                  <i class="mdi mdi-arrow-left"></i> Retour
                </button>
              </div>

              <b-alert v-model="loaded" variant="info" class="mt-3">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h5 class="alert-heading">Fichier importé avec succès</h5>
                    <p>
                      Veuillez vérifier les informations ci-dessous et cliquez
                      sur le bouton "Envoyer" pour envoyer les emails
                    </p>
                  </div>
                  <div class="ms-3">
                    <button
                      type="button"
                      class="btn btn-success btn-xl"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </b-alert>

              <!-- Modal -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Confirmation
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <p>
                        Confirmez-vous l'envoi des emails à tous les
                        destinataires ?
                      </p>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        @click.prevent="handleSendEmails"
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-4">
                    <h5 class="card-title flex-grow-1">Liste des fichiers</h5>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="table-responsive table-card">
                        <table class="table table-borderless align-middle">
                          <thead class="table-light">
                            <tr>
                              <th scope="col">Entreprise</th>
                              <th scope="col">DG</th>
                              <th scope="col">Email</th>
                              <th scope="col">Téléphone</th>
                              <th scope="col">Statut</th>
                              <th scope="col" style="width: 120px">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="doc of files" :key="doc.id">
                              <td>{{ doc.name }}</td>
                              <td>{{ doc.dg }}</td>
                              <td>{{ doc.email }}</td>
                              <td>{{ doc.phone_number }}</td>
                              <!-- <td>12 Dec 2021</td> -->
                              <td>
                                <!-- Badge according to status (pending, loading, error, success) -->
                                <!-- Spinner for loading -->

                                <span
                                  v-if="doc.status === 'pending'"
                                  class="badge bg-soft-warning text-warning"
                                  >En attente</span
                                >

                                <!-- Display loader bootstrap -->
                                <span
                                  v-if="doc.status === 'loading'"
                                  class="spinner-border spinner-border-sm text-primary"
                                  role="status"
                                  aria-hidden="true"
                                ></span>

                                <span
                                  v-if="doc.status === 'error'"
                                  class="badge bg-soft-danger text-danger"
                                >
                                  Erreur
                                </span>

                                <span
                                  v-if="doc.status === 'success'"
                                  class="badge bg-soft-success text-success"
                                  >Envoyé</span
                                >
                              </td>
                              <td>
                                <div class="dropdown">
                                  <a
                                    href="javascript:void(0);"
                                    class="btn btn-soft-secondary btn-sm btn-icon"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <i class="ri-more-fill"></i>
                                  </a>
                                  <ul class="dropdown-menu dropdown-menu-end">
                                    <li
                                      v-show="false"
                                      v-if="doc.status == 'success'"
                                    >
                                      <a
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                        ><i
                                          class="ri-chart-fill me-2 align-bottom text-muted"
                                        ></i
                                        >Rapport d'envoie</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        @click.prevent="
                                          $openQrCode(doc.matricule)
                                        "
                                        ><i
                                          class="ri-eye-fill me-2 align-bottom text-muted"
                                        ></i>
                                        Afficher le QrCode
                                      </a>
                                    </li>

                                    <!-- divider -->
                                    <template
                                      v-if="
                                        doc.status != 'pending' &&
                                        doc.status != 'loading'
                                      "
                                    >
                                      <li>
                                        <hr class="dropdown-divider" />
                                      </li>

                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                          @click.prevent="
                                            handleResendEmail(doc)
                                          "
                                          ><i
                                            class="ri-refresh-line me-2 align-bottom text-muted"
                                          ></i
                                          >Renvoyer l'email</a
                                        >
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                          @click.prevent="handleResendSMS(doc)"
                                          ><i
                                            class="ri-refresh-line me-2 align-bottom text-muted"
                                          ></i
                                          >Renvoyer le SMS</a
                                        >
                                      </li>
                                    </template>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- <div class="text-center mt-3">
                      <a href="javascript:void(0);" class="text-success"
                        ><i
                          class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                        ></i>
                        Load more
                      </a>
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <!-- super -->

      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
